import { Route, Routes, Link, useParams } from 'react-router-dom';
import { useState, useEffect, MutableRefObject, useRef } from 'react';
import BasicSearch from './BasicSearch';
import AdvancedSearch from './AdvancedSearch';
import useGetSettings from '../Hooks/useGetSettings';
import CarouselWrapper from './CarouselWrapper';
import VideoCarousel from './VideoCarousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCross, faFileSignature, faMapLocationDot, faMedal, faNewspaper, faSignature } from '@fortawesome/free-solid-svg-icons';

interface IHomeProps {
}

function Home(props: IHomeProps) {
  const [isStandard, setIsStandard] = useState(false);
  const [isAdvanced, setIsAdvanced] = useState(true);
  const { data: settings } = useGetSettings();
  const [localListActive, setLocalListActive] = useState(false);
  const [cemeteryListActive, setCemeteryListActive] = useState(false);
  const [veteranListActive, setVeteranListActive] = useState(false);

  const { searchTerm } = useParams<{ searchTerm: string }>();

  function handleSearchType(type = 'standard') {
    if(type === 'standard') {
      setIsStandard(true);
      setIsAdvanced(false);
    } else {
      setIsStandard(false);
      setIsAdvanced(true);
    }
  }

  useEffect(() => {
    setIsStandard(false);
    setIsAdvanced(true);
  }, [searchTerm]);

  let standardClass = 'p-4 ';
  let advancedClass = 'p-4 ';
  if(isStandard) {
    standardClass += 'bg-secondary text-white';
    advancedClass += 'bg-gray-300';
  } else {
    advancedClass += 'bg-secondary text-white';
    standardClass += 'bg-gray-300';
  }

  return (
    <>
      {/* <div className='h-[1000px] w-full'> */}
        <CarouselWrapper />
      {/* </div> */}
      <div className='flex flex-col place-items-center gap-y-8 lg:px-40 pb-8 text-xs lg:text-base'>
        <div className='flex flex-col gap-2 justify-center place-items-center overflow-hidden'>
          { isStandard &&
            <span className={`font-bold text-center lg:px-80`}>
              Enter a first name, last name, or keyword to begin your search.
              Choosing a specific database from the dropdown menu will help narrow your search results.
              You can also narrow your search down further by clicking the Advanced Search Tab.
            </span>
          }
          { isAdvanced &&
            <span className='font-bold text-center lg:px-80'>
              Choose a database from the dropdown menu to begin your search. You can search across all categories at once by clicking Broad Search.
            </span>
          }
        </div>
        <Link to='/databases' className='hidden text-primary font-bold underline underline-offset-2'>
          Learn more about our databases
        </Link>
        <div className='flex bg-gray-300 p-1 text-sm lg:text-lg'>
          <button type='button' className={standardClass} onClick={() => { handleSearchType() }}>Overall Search</button>
          <button type='button' className={advancedClass} onClick={() => { handleSearchType('advanced') }}>Advanced Search</button>
        </div>
        { isStandard &&
          <BasicSearch searchTerm={searchTerm} />
        }
        { isAdvanced &&
          <AdvancedSearch />
        }
        <h1 className='text-2xl font-semibold'>WHAT YOU CAN FIND IN OUR DATABASES</h1>
        <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8 mb-8 place-items-center'>
          <div className='flex flex-col gap-2 justify-center place-items-start self-start'>
            <FontAwesomeIcon className='text-6xl self-center text-[#056839]' icon={faNewspaper} />
            <h2 className='font-bold self-center'>Obituaries</h2>
            <ul className='list-disc text-xs md:text-sm max-h-16 px-4'>
              <li>Obituaries from Local Newspapers and Funeral Homes</li>
              <li>Southwestern IN & Southeastern IL</li>
              <li>Northwestern KY</li>
              <li>Evansville, IN - Courier & Press</li>
            </ul>
          </div>
          <div className='relative flex flex-col gap-2 place-items-start self-start'>
            <FontAwesomeIcon className='text-6xl self-center text-[#056839]' icon={faMapLocationDot} />
            <h2 className='font-bold self-center'>Local History</h2>
            <ul className={`list-disc text-xs md:text-sm px-4 ${localListActive ? '' : 'max-h-16 overflow-hidden'}`}>
              <li>Marriage Engagement, & Birth Records (Evansville, IN)</li>
              <li>Local Newspaper Articles (Evansville, IN - 1950s-2010s)</li>
              <li>Business Entities (Evansville, IN)</li>
              <li>St. Mary's School of Nursing Yearbooks (Evansville, IN - 1930s-1960s)</li>
              <li>Deaconess School of Nursing Yearbooks (Evansville, IN - 1930s-1990s)</li>
              <li>Bucyrus Erie SCOOPS</li>
              <li>DAR Historical Markers (Evansville, IN)</li>
              <li>Interstate Finance (Evansville, IN)</li>
              <li>Western KY Local History</li>
              <li>Over 700 local High School Senior Photos (Vanderburgh, Vanderburgh, & Posey County, IN)</li>
            </ul>
            { !localListActive &&
              <span
                className='absolute -bottom-8 left-4 underline cursor-pointer'
                onClick={() => {
                  setLocalListActive(true);
                }}
              >View More</span>
            }
          </div>
          <div className='relative flex flex-col gap-2 place-items-start self-start'>
            <FontAwesomeIcon className='text-6xl self-center text-[#056839]' icon={faCross} />
            <h2 className='font-bold self-center'>Cemetery Records</h2>
            <ul className={`list-disc text-xs md:text-sm px-4 ${cemeteryListActive ? '' : 'max-h-16 overflow-hidden'}`}>
              <li>Rose Hill (Warrick Co., IN)</li>
              <li>Snake Run (Gibson Co., IN)</li>
              <li>New Salem (Vanderburgh Co., IN)</li>
              <li>James Parker (Spencer Co., IN)</li>
              <li>Folsomville Cemetery (Warrick Co., IN)</li>
              <li>Shiloh Cemetery (Warrick Co., IN)</li>
              <li>St. John the Baptitst Cemetery (Warrick Co., IN)</li>
              <li>Blythe Cemetery (Gibson Co., IN)</li>
              <li>Liberty Cemetery (Posey Co., IN)</li>
            </ul>
            { !cemeteryListActive &&
              <span
                className='absolute -bottom-8 left-4 underline cursor-pointer'
                onClick={() => {
                  setCemeteryListActive(true);
                }}
              >View More</span>
            }
          </div>
          <div className='relative flex flex-col gap-2 place-items-start self-start'>
            <FontAwesomeIcon className='text-6xl self-center text-[#056839]' icon={faMedal} />
            <h2 className='font-bold self-center'>Veteran Records & WWII Letters</h2>
            <ul className={`list-disc text-xs md:text-sm max-h-16 px-4 ${veteranListActive ? '' : 'max-h-16 overflow-hidden'}`}>
              <li>Posey Co., IN</li>
              <li>Pike Co., IN</li>
              <li>Vanderburgh Co., IN</li>
              <li>Over 3,000 Scans of Original Letters from WW2 Veterans to First Baptist Church Reverend Rake (Evansville, IN)</li>
            </ul>
            { !veteranListActive &&
              <span
                className='absolute -bottom-8 left-4 underline cursor-pointer'
                onClick={() => {
                  setVeteranListActive(true);
                }}
              >View More</span>
            }
          </div>
          <div className='flex flex-col gap-2 place-items-start self-start'>
            <FontAwesomeIcon className='text-6xl self-center text-[#056839]' icon={faFileSignature} />
            <h2 className='font-bold self-center'>Immigration Records</h2>
            <ul className='list-disc text-xs md:text-sm max-h-16 px-4'>
              <li>Warrick Co., IN (1847-1920)</li>
              <li>Vanderburgh Co., IN (1847-1920)</li>
            </ul>
          </div>
        </div>
        <div className='hidden lg:flex flex-col place-items-center gap-4'>
          <VideoCarousel />
        </div>
      </div>
    </>
  );
}
  
  export default Home;