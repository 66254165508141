import React, { useState } from 'react';
import { useFieldTypeStore } from '../Hooks/useFieldTypeStore';

interface IFormEntryProps {
    fieldType?: number;
    id?: string;
    onChange?: Function;
    value: string|number;
    className?: string;
    placeholder?: string;
    name?: string;
    readonly?: boolean;
    onKeyDown?: Function;
    disabled?: boolean;
    useDateRange?: boolean;
    dateMin?: string;
}

const fieldTypeMap : { [key: string]: string} = {
    String: 'text',
    Integer: 'number',
    Date: 'date'
};

function FormEntryField(props: IFormEntryProps) {
    const fieldTypes = useFieldTypeStore((state) => state.fieldTypes);
    let fieldType = 'text';
    if(props.fieldType && fieldTypes) {
      fieldType = fieldTypeMap[fieldTypes[props.fieldType]];
    }
    return (
        <input
            autoComplete='off'
            type={fieldType}
            id={props.id ?? ''}
            readOnly={props.readonly ?? false}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if(props.onChange) {
                if(props.useDateRange) {
                  let type = props?.id?.match(/_start$/) ? 'start' : 'end';
                  props.onChange(e, type);
                } else {
                  props.onChange(e);
                }
              }
            }}
            value={props.value}
            className={props.className ?? ''}
            placeholder={props.placeholder ?? ''}
            name={props.name}
            onKeyDown={(e: React.KeyboardEvent) => {
              props.onKeyDown && props.onKeyDown(e);
            }}
            disabled={props.disabled ?? false}
            min={props.dateMin ?? ''}
        />
    );
}

export default FormEntryField;