import React, { useState } from 'react';
import useSubmitFeedback from '../Hooks/useSubmitFeedback';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLocationDot, faLocationPin } from '@fortawesome/free-solid-svg-icons';
import useGetSettings from '../Hooks/useGetSettings';
import { faFacebook, faFacebookF, faInstagram, faTwitter, faYelp, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { useAppStore } from '../Hooks/useAppStore';
import { NotificationTypes } from '../Interfaces/Notification';
import ReCAPTCHA from './ReCAPTCHA';

const settingIconMap = {
  address: <FontAwesomeIcon icon={faLocationDot} />,
  email: <FontAwesomeIcon icon={faEnvelope} />,
  facebook: <FontAwesomeIcon icon={faFacebookF} />,
  twitter: <FontAwesomeIcon icon={faTwitter} />,
  youtube: <FontAwesomeIcon icon={faYoutube} />,
  instagram: <FontAwesomeIcon icon={faInstagram} />,
  yelp: <FontAwesomeIcon icon={faYelp} />
}

const Contact = () => {
  const { data: settings } = useGetSettings();
  const [ name, setName ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ message, setMessage ] = useState('');
  const setShowMailingList = useAppStore((state) => state.setShowMailingList);
  const [ errors, setErrors ] = useState<string[]>([]);
  const { mutate: saveFeedback } = useSubmitFeedback();

  const getAddress = () => {
    return (settings.address.replace(/\s/g, '+') + (settings.address2 ? '+' + settings.address2.replace(/\s/g, '+') : ''));
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>, set: Function) => {
    set(e.target.value);
  }

  const submitFeedback = (token: string) => {
    saveFeedback({
      name, email, message, token
    }, {
      onSuccess: () => {
        setEmail('');
        setName('');
        setMessage('');
        useAppStore.setState({
          message: {
            message: "Successfully submitted feedback",
            time: 4000,
            type: NotificationTypes.Success
          }
        });
      }
    });
  }

  const handleSubmit = (token: string) => {
    submitFeedback(token);
  }

  return (
    <ReCAPTCHA action='feedback' onSubmit={handleSubmit}>
      <div className='flex flex-col px-40 my-8'>
        <h1 className='text-center font-bold'>Contact Us</h1>
        <div className='grid grid-cols-1 gap-8 px-40 lg:px-96'>
          <div className='flex flex-col gap-4'>
            <div className='flex flex-col'>
              <label htmlFor='user_name'>Name</label>
              <input
                id='user_name'
                className='p-2 border border-gray-200 rounded outline-none ring-primary focus:ring-2'
                value={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, setName)}
              />
            </div>
            <div className='flex flex-col'>
              <label htmlFor='user_email'>Email</label>
              <input
                id='user_email'
                type='email'
                className='p-2 border border-gray-200 rounded outline-none ring-primary focus:ring-2'
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, setEmail)}
              />
            </div>
            <div className='flex flex-col'>
              <label htmlFor='user_message'>Message</label>
              <textarea
                id='user_message'
                className='p-2 border border-gray-200 rounded outline-none ring-primary focus:ring-2'
                value={message}   
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleChange(e, setMessage)}
              ></textarea>
            </div>
            <div className='flex justify-between place-items-center'>
              <button
                data-sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}
                data-action='feedback'
                className='button-submit'
              >Submit Feedback</button>
              {/*
              <span
                className='underline underline-offset-2 cursor-pointer'
                onClick={() => setShowMailingList(true)}
              >Would you like to join our mailing list?</span>
              */}
            </div>
          </div>
        </div>
      </div>
    </ReCAPTCHA>
  )
}

export default Contact;