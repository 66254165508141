import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import API from '../Utility/API';
import { useLocation, useNavigate } from 'react-router-dom';
import useSearchRelated from '../Hooks/useSearchRelated';
import SearchResultSet from './SearchResultSet';
import Util from '../Utility/Util';

const RelatedSearch = (props: any) => {
  const navigate = useNavigate();
  const { entryMetadataId, databaseId, relatedSearch } = JSON.parse(localStorage.getItem('temp-state') ?? '{}');
  if(!entryMetadataId || !databaseId) {
    navigate('/');
  }
  const [ page, setPage ] = useState(1);
  const [ resultsPerPage, setResultsPerPage ] = useState(localStorage.getItem(`paginate-related-search`) ?? 9);
  const { data, isLoading, refetch } = useSearchRelated({
    entryMetadataId,
    databaseId,
    page,
    resultsPerPage: Number(resultsPerPage)
  });

  const handlePageChange = (page: number) => {
    setPage(page);
  }

  return (
    <div className='flex flex-col justify-center my-8 gap-4'>
      { isLoading &&
        <span className='text-center'>Loading...</span>
      }
      { data && data.entries && data.entries.length > 0 &&
        <>
          <span className='text-center'>{`Related Entries` + (relatedSearch ? `: ${relatedSearch}` : '')}</span>
          <SearchResultSet
            refetchData={refetch}
            recordCount={data.entries.length}
            fieldMapping={{}}
            dataset={data}
            recordTypeMap={{}}
            totalCount={data.totalCount}
            paginateKey='related-search'
            currentPage={page}
            onChange={handlePageChange}
          />
        </>
      }
    </div>
  );
}

export default RelatedSearch;